export const sygnet = ['420 420', `
  <title>DLT Software - FinTech & DeFi Software Solutions</title>
  <svg width="420px" height="420px" viewBox="0 0 420 420" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="symbol-in-white" stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
        <path d="M108.017286,108.017286 L210,108.017286 L210,60 L96.0466691,60 C76.1388869,60 60,76.1388869 60,96.0466691 L60,210 L108.017286,210 L108.017286,108.017286 Z" id="Fill-1" fill="#14F5C2"></path>
        <polygon id="Fill-3" fill="currentColor" points="121 265.351709 154.648291 299 299 154.648291 265.351709 121"></polygon>
        <path d="M311.982714,311.982714 L210,311.982714 L210,360 L323.959795,360 C343.864345,360 360,343.864345 360,323.959795 L360,210 L311.982714,210 L311.982714,311.982714 Z" id="Fill-5" fill="#14F5C2"></path>
    </g>
  </svg>
`]
