import React from 'react';

const Dashboard = React.lazy(() =>  import('./views/dashboard/Dashboard'));    // Dashboard
const Dashboard2 = React.lazy(() => import('./views/dashboard/Dashboard2'));  // Markets
const Dashboard3 = React.lazy(() => import('./views/dashboard/Dashboard3'));  // Analytics
const Dashboard4 = React.lazy(() => import('./views/dashboard/Dashboard4'));  // Staking
const Dashboard5 = React.lazy(() => import('./views/dashboard/Dashboard5'));  // Wallet
const Dashboard6 = React.lazy(() => import('./views/dashboard/Dashboard6'));  // Analytics2

const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));

// const Analytics = React.lazy(() => import('./views/markets/Analytics'));
const CoreUIIcons = React.lazy(() => import('./views/icons/coreui-icons/CoreUIIcons'));
const Colors = React.lazy(() => import('./views/theme/colors/Colors'));
const Typography = React.lazy(() => import('./views/theme/typography/Typography'));

const routes = [
  { path: '/', exact: true, name: 'Home', component: Dashboard, additionalPadding: false },

  { path: '/login', name: 'Login', component: Login, exact: true },

  { path: '/theme', name: 'Theme', component: Colors, exact: true },
  { path: '/theme/colors', name: 'Colors', component: Colors },
  { path: '/theme/typography', name: 'Typography', component: Typography },
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
];

export default routes;
