import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ApiServer } from './ApiServer'

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './store'

React.icons = icons

if (process.env.NODE_ENV === "development") {
  ApiServer()
}

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//
// - 15:09 20.10.2021
// moved to: https://github.com/facebook/create-react-app/blob/main/packages/cra-template/template/README.md
// read more: https://create-react-app.dev/docs/making-a-progressive-web-app/

serviceWorker.unregister();
//serviceWorker.register();