export const logoNegative = ['255 56' , `
  <title>DLT Software - FinTech & DeFi Software Solutions</title>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-14 0 532 100">
    <defs>
      <style>
        .cls-1{fill:#14f5c2;}
        .cls-2{fill:currentColor;}
        /*.cls-2{fill:#1e2832;}*/
      </style>
    </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path class="cls-1" d="M16,16H50V0H12A12,12,0,0,0,0,12V50H16Z"/>
          <rect class="cls-2" x="16.06" y="42.09" width="67.88" height="15.82" transform="translate(-20.71 50) rotate(-45)"/>
          <path class="cls-1" d="M84,84H50v16H88a12,12,0,0,0,12-12V50H84Z"/>
          <path class="cls-2" d="M131.2,15.69h18.21c22.41,0,36.31,12.82,36.31,34.26,0,21.93-14,34.26-36.31,34.26H131.2Zm18.7,56.58c13,0,21.43-7.54,21.43-22.32,0-14.39-8.41-22.22-21.43-22.22H145V72.27Z"/>
          <path class="cls-2" d="M196.75,15.69h13.8V71.78h22.32V84.21H196.75Z"/>
          <path class="cls-2" d="M249.85,28.12h-16V15.69h45.81V28.12h-16V84.21h-13.8Z"/>
          <path class="cls-2" d="M294.13,67.47c1.86,5.29,6,10.67,15,10.67,7.25,0,12.44-4.21,12.44-11.35,0-17.14-32.11-12.73-32.11-34.85,0-9.79,7.44-17.52,19.58-17.52a20,20,0,0,1,19.38,13.11l-6.66,2.74c-1.57-3.42-5.28-8.61-12.82-8.61s-11.55,4.6-11.55,10.28c0,15.66,32.2,12.43,32.2,34.36,0,10.76-7.93,19.18-20.75,19.18-13.31,0-19.28-8.51-21.44-15.07Z"/>
          <path class="cls-2" d="M374.27,14.42c20,0,34.94,15.56,34.94,35.53,0,20.16-15.07,35.53-35,35.53S339.32,70.31,339.32,50C339.32,29.78,354.4,14.42,374.27,14.42Zm0,63.62C389.44,78,401,66.2,401,50c0-16.05-11.55-28.1-26.72-28.1s-26.72,12-26.72,28.1C347.55,66.2,359.1,78,374.27,78Z"/>
          <path class="cls-2" d="M420.47,15.69H453v7.24H428.4V46.42h21.53v7.15H428.4V84.21h-7.93Z"/>
          <path class="cls-2" d="M479.46,22.93H461V15.69h44.83v7.24h-18.4V84.21h-7.93Z"/>
        </g>
      </g>
    </svg>
`]

/*

export const logoNegative = ['420 240', `
  <title>coreui react pro logo</title>
  <g xmlns="http://www.w3.org/2000/svg" id="horizontal-in-white" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-21" transform="translate(50.000000, 80.000000)">
            <path d="M9.60004,9.6 L29.98924,9.6 L29.98924,0 L7.20684,0 C3.22644,0 4e-05,3.2264 4e-05,7.2068 L4e-05,29.9892 L9.60004,29.9892 L9.60004,9.6 Z" id="Fill-1" fill="#14F5C2"/>
            <polygon id="Fill-3" fill="#1E2832" points="12.2434 41.04336 18.9566 47.75656 47.7566 18.95656 41.0434 12.24336"/>
            <path d="M50.40004,50.4 L30.01084,50.4 L30.01084,60 L52.79444,60 C56.77404,60 60.00004,56.774 60.00004,52.7944 L60.00004,30.0108 L50.40004,30.0108 L50.40004,50.4 Z" id="Fill-5" fill="#14F5C2"/>
            <path d="M89.93832,43.36036 C97.74952,43.36036 102.80032,38.83796 102.80032,29.96956 C102.80032,21.33556 97.74952,16.63676 89.93832,16.63676 L87.00152,16.63676 L87.00152,43.36036 L89.93832,43.36036 Z M78.71992,9.41276 L89.64472,9.41276 C103.09472,9.41276 111.43432,17.10676 111.43432,29.96956 C111.43432,43.12556 103.03592,50.52596 89.64472,50.52596 L78.71992,50.52596 L78.71992,9.41276 Z" id="Fill-7" fill="#1E2832"/>
            <polygon id="Fill-9" fill="#1E2832" points="118.04808 9.41268 126.32968 9.41268 126.32968 43.06668 139.72048 43.06668 139.72048 50.52588 118.04808 50.52588"/>
            <polygon id="Fill-11" fill="#1E2832" points="149.911 16.87168 140.2786 16.87168 140.2786 9.41288 167.7658 9.41288 167.7658 16.87168 158.1918 16.87168 158.1918 50.52568 149.911 50.52568"/>
            <path d="M176.47584,40.48244 C177.59184,43.65444 180.05864,46.88444 185.46224,46.88444 C189.80784,46.88444 192.92104,44.35884 192.92104,40.07164 C192.92104,29.79284 173.65704,32.43644 173.65704,19.16244 C173.65704,13.28924 178.12024,8.64924 185.40304,8.64924 C192.74504,8.64924 196.03384,13.75844 197.03264,16.51964 L193.03864,18.16404 C192.09904,16.10804 189.86744,12.99564 185.34464,12.99564 C180.82184,12.99564 178.41384,15.75604 178.41384,19.16244 C178.41384,28.55924 197.73704,26.62164 197.73704,39.77764 C197.73704,46.23844 192.97984,51.28964 185.28584,51.28964 C177.29784,51.28964 173.71584,46.17924 172.42344,42.24444 L176.47584,40.48244 Z" id="Fill-13" fill="#1E2832"/>
            <path d="M224.56136,46.8256 C233.66496,46.8256 240.59496,39.7192 240.59496,29.9696 C240.59496,20.3372 233.66496,13.1128 224.56136,13.1128 C215.45736,13.1128 208.52696,20.3372 208.52696,29.9696 C208.52696,39.7192 215.45736,46.8256 224.56136,46.8256 M224.56136,8.6496 C236.54256,8.6496 245.52856,17.9876 245.52856,29.9696 C245.52856,42.068 236.48416,51.2896 224.50216,51.2896 C212.57976,51.2896 203.59336,42.186 203.59336,29.9696 C203.59336,17.8704 212.63816,8.6496 224.56136,8.6496" id="Fill-15" fill="#1E2832"/>
            <polygon id="Fill-17" fill="#1E2832" points="252.28052 9.41268 271.77972 9.41268 271.77972 13.75868 257.03772 13.75868 257.03772 27.85468 269.95892 27.85468 269.95892 32.14228 257.03772 32.14228 257.03772 50.52588 252.28052 50.52588"/>
            <polygon id="Fill-19" fill="#1E2832" points="287.677 13.7586 276.5762 13.7586 276.5762 9.4126 303.4758 9.4126 303.4758 13.7586 292.4346 13.7586 292.4346 50.5258 287.677 50.5258"/>
        </g>
  </g>
`]

 */



/*

    <defs>
        <polygon id="path-1" points="0.516463291 0.482696203 56.4926278 0.482696203 56.4926278 56.4631177 0.516463291 56.4631177"></polygon>
        <polygon id="path-3" points="0 0.148913043 38.6623309 0.148913043 38.6623309 49.8025362 0 49.8025362"></polygon>
        <polygon id="path-5" points="0.124216279 0.67760137 30.5402628 0.67760137 30.5402628 52.2739726 0.124216279 52.2739726"></polygon>
        <polygon id="path-7" points="0.0880647887 0.677456164 50.2921775 0.677456164 50.2921775 52.2739726 0.0880647887 52.2739726"></polygon>
        <polygon id="path-9" points="0.545432609 0.148913043 32.7080217 0.148913043 32.7080217 49.8025362 0.545432609 49.8025362"></polygon>
    </defs>
    <g id="vertical-in-white" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-3" transform="translate(154.000000, 111.000000)">
            <mask id="mask-2" fill="white">
                <use xlink:href="#path-1"></use>
            </mask>
            <g id="Clip-2"></g>
            <path d="M18.4288937,18.3950544 L56.4926278,18.3950544 L56.4926278,0.482624051 L13.9634127,0.482624051 C6.53681772,0.482624051 0.516463291,6.50297848 0.516463291,13.9295734 L0.516463291,56.4631177 L18.4288937,56.4631177 L18.4288937,18.3950544 Z" id="Fill-1" fill="#14F5C2" mask="url(#mask-2)"></path>
        </g>
        <polygon id="Fill-4" fill="#1E2832" points="176 189.565125 188.43546 202 244 146.434875 231.56454 134"></polygon>
        <path d="M248.079942,205.078325 L210,205.078325 L210,223 L252.54949,223 C259.977804,223 266,216.97726 266,209.548276 L266,167 L248.079942,167 L248.079942,205.078325 Z" id="Fill-6" fill="#14F5C2"></path>
        <g id="Group-10" transform="translate(76.000000, 259.000000)">
            <mask id="mask-4" fill="white">
                <use xlink:href="#path-3"></use>
            </mask>
            <g id="Clip-9"></g>
            <path d="M13.2577309,41.1481884 C22.4893855,41.1481884 28.4585127,35.6865942 28.4585127,24.9757246 C28.4585127,14.5481884 22.4893855,8.87355072 13.2577309,8.87355072 L9.78744,8.87355072 L9.78744,41.1481884 L13.2577309,41.1481884 Z M-0.000141818182,0.148913043 L12.9109855,0.148913043 C28.8059673,0.148913043 38.6623309,9.44094203 38.6623309,24.9757246 C38.6623309,40.8648551 28.7364764,49.8025362 12.9109855,49.8025362 L-0.000141818182,49.8025362 L-0.000141818182,0.148913043 Z" id="Fill-8" fill="#1E2832" mask="url(#mask-4)"></path>
        </g>
        <polygon id="Fill-11" fill="#1E2832" points="123 260 132.934994 260 132.934994 300.109892 149 300.109892 149 309 123 309"></polygon>
        <polygon id="Fill-13" fill="#1E2832" points="161.564263 268.890108 150 268.890108 150 260 183 260 183 268.890108 171.50633 268.890108 171.50633 309 161.564263 309"></polygon>
        <g id="Group-17" transform="translate(188.000000, 258.000000)">
            <mask id="mask-6" fill="white">
                <use xlink:href="#path-5"></use>
            </mask>
            <g id="Clip-16"></g>
            <path d="M4.99337907,39.1969849 C6.3343093,43.0347658 9.29805349,46.9436973 15.7907512,46.9436973 C21.0124488,46.9436973 24.7533558,43.8871219 24.7533558,38.6996562 C24.7533558,26.2620808 1.60644884,29.4609575 1.60644884,13.3990534 C1.60644884,6.29124521 6.96944884,0.67760137 15.7201,0.67760137 C24.5414023,0.67760137 28.4928209,6.85972466 29.6924488,10.2009027 L24.8939372,12.1902178 C23.7649605,9.70284795 21.0838209,5.93621781 15.6494488,5.93621781 C10.2150767,5.93621781 7.32198372,9.27666986 7.32198372,13.3990534 C7.32198372,24.7693685 30.5402628,22.4243 30.5402628,38.3439027 C30.5402628,46.1617658 24.824007,52.2741904 15.5787977,52.2741904 C5.98105349,52.2741904 1.67637907,46.0906151 0.124216279,41.3286014 L4.99337907,39.1969849 Z" id="Fill-15" fill="#1E2832" mask="url(#mask-6)"></path>
        </g>
        <g id="Group-20" transform="translate(225.000000, 258.000000)">
            <mask id="mask-8" fill="white">
                <use xlink:href="#path-7"></use>
            </mask>
            <g id="Clip-19"></g>
            <path d="M25.1901211,46.8724014 C36.0883183,46.8724014 44.3855155,38.2733329 44.3855155,26.4753877 C44.3855155,14.8204699 36.0883183,6.07837397 25.1901211,6.07837397 C14.2912056,6.07837397 5.99400845,14.8204699 5.99400845,26.4753877 C5.99400845,38.2733329 14.2912056,46.8724014 25.1901211,46.8724014 M25.1901211,0.677456164 C39.5340507,0.677456164 50.2921775,11.9773466 50.2921775,26.4753877 C50.2921775,41.1157301 39.4636563,52.2740452 25.1190085,52.2740452 C10.8454732,52.2740452 0.0880647887,41.2580315 0.0880647887,26.4753877 C0.0880647887,11.8350452 10.9158676,0.677456164 25.1901211,0.677456164" id="Fill-18" fill="#1E2832" mask="url(#mask-8)"></path>
        </g>
        <polygon id="Fill-21" fill="#1E2832" points="284 260 307 260 307 265.179461 289.61093 265.179461 289.61093 281.979992 304.852729 281.979992 304.852729 287.090088 289.61093 287.090088 289.61093 309 284 309"></polygon>
        <g id="Group-25" transform="translate(312.000000, 259.000000)">
            <mask id="mask-10" fill="white">
                <use xlink:href="#path-9"></use>
            </mask>
            <g id="Clip-24"></g>
            <polygon id="Fill-23" fill="#1E2832" mask="url(#mask-10)" points="13.8178891 5.39746377 0.545432609 5.39746377 0.545432609 0.148913043 32.708237 0.148913043 32.708237 5.39746377 19.5060848 5.39746377 19.5060848 49.8025362 13.8178891 49.8025362"></polygon>
        </g>
    </g>

 */