import React from "react";

export const themes = {
  light: {
    'theme': 'light'
  },
  dark: {
    'theme': 'dark'
  }
}

export interface ThemeState {
  theme: typeof themes.light | typeof themes.dark,
  toggleDarkTheme: () => {}
}

export const ThemeContext = React.createContext({
  /* default theme */
  'theme': themes.light,
  'toggleDarkTheme': () => {}
})

