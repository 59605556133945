import React, {Component, useContext, useEffect, useState} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import './scss/style.scss';
import routes from "./routes";
import { ThemeContext, themes} from "./reusable/ThemeContext"
import { CSpinner } from '@coreui/react'

const loading = (
  <div className="c-app-loader">
    <CSpinner component="span" visuallyhiddenlabel="Loading..."></CSpinner>
  </div>
  // <div className="pt-3 text-center">
  //   <div className="sk-spinner sk-spinner-pulse"></div>
  // </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {
  const [themeState, setThemeState] = useState({
     theme: "light", //null,
     toggleDarkTheme: null
  })

  const setGlobalTheme = (newTheme) => {
    document.body.classList.add("transition-none");
    document.documentElement.setAttribute('theme',newTheme.theme);
    // -- transition-none class needs to be removed after some delay
    const timeoutID = setTimeout(function() {
      document.body.classList.remove("transition-none");
    }, 199)
    // -- return cleanup function
    return () => {
      // -- timeoutID variable is available in this function (closure)
      clearTimeout(timeoutID);
    }
  }

  const toggleDarkThemeFunction = () => {
    setThemeState((prevThemeState) => {
      const newTheme = (prevThemeState.theme === themes.dark) ? themes.light : themes.dark
      setGlobalTheme(newTheme)

      return {
        ...prevThemeState,
        theme: newTheme
      }
    })

  }

  useEffect(() => {
    setThemeState({
      theme: themes.light,
      toggleDarkTheme: toggleDarkThemeFunction
    })
    setGlobalTheme(themeState)
  }, [])

  return (
    <ThemeContext.Provider value={themeState}>
      <div className="theme-wrapper" {...themeState.theme}>

      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>}/>
            <Route exact path="/404" name="Page 404">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>}/>
            <Route index exact path="/" name="Home" >
              <Redirect to="/login" />
            </Route>
            <Route path="*" name="Page 404" >
              <Redirect to="/login" />
            </Route>
          </Switch>
        </React.Suspense>
      </BrowserRouter>
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
